<template>
    <component :is="userData.kd_produk === undefined ? 'div' : 'b-card'">

        {{ userData }}
        <!-- Alert: No item found -->
        <b-alert
            variant="danger"
            :show="userData.kd_produk === undefined"
        >
            <h4 class="alert-heading">
                Error fetching user data
            </h4>
            <div class="alert-body">
                No Produk found with id. Check
                <b-link
                    class="alert-link"
                    :to="{ name: 'apps-produk-list'}"
                >
                    Produk List
                </b-link>
                for other produk.
            </div>
        </b-alert>

        <b-overlay
            spinner-variant="primary"
            id="overlay-background"
            variant="transparent"
            opacity="1.00"
            blur="0.5em"
            rounded="sm"
            :show="isSubmit">
            <b-form
                @submit.prevent="onSubmit"
            >

                <b-row>
                    <b-col
                        cols="12"
                        md="4"
                    >
                        <b-form-group
                            label="Nama Produk"
                            label-for="nama_produk"
                        >
                            <b-form-input
                                id="nama_produk"
                                v-model="userData.nama_produk"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        md="2"
                    >
                        <b-form-group
                            label="Stok"
                            label-for="stok"
                        >
                            <b-form-input
                                id="stok"
                                v-model="userData.stock"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        md="2"
                    >
                        <b-form-group
                            label="Kode Garansi"
                            label-for="kd_garansi"
                        >
                            <b-form-input
                                id="kd_garansi"
                                v-model="userData.kd_garansi"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        md="2"
                    >
                        <b-form-group
                            label="berat"
                            label-for="berat"
                        >
                            <b-form-input
                                id="berat"
                                v-model="userData.berat"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        md="2"
                    >
                        <b-form-group
                            label="satuan"
                            label-for="satuan"
                        >
                            <b-form-input
                                id="satuan"
                                v-model="userData.satuan"
                            />
                        </b-form-group>
                    </b-col>
                    <!-- Field: spesifikasi -->
                    <b-col
                        cols="12"
                    >
                        <b-form-group
                            label="Spesifikasi"
                            label-for="spesifikasi"
                        >
                            <b-form-textarea
                                :rows="5"
                                :max-rows="10"
                                id="spesifikasi"
                                v-model="userData.spesifikasi"
                                type="spesifikasi"
                            />
                        </b-form-group>
                    </b-col>

                    <!-- Field: Status -->
                    <b-col
                        cols="12"
                        md="4"
                    >
                        <b-form-group
                            label="No Hp"
                            label-for="no_hp"
                        >
                            <b-form-input
                                id="no_hp"
                                v-model="userData.no_hp"
                            />
                        </b-form-group>
                    </b-col>

                    <!-- Field: Role -->
                    <b-col
                        cols="12"
                        md="4"
                    >
                        <b-form-group
                            label="Gender"
                            label-for="gender-list"
                        >
                            <v-select
                                v-model="userData.gender"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="[
                  'Pria','Perempuan'
              ]"
                                :clearable="false"
                                input-id="gender-list"
                            />
                        </b-form-group>
                    </b-col>

                    <b-col
                        cols="12"
                        md="4"
                    >
                        <b-form-group
                            label="Pasword (kosongkan jika tidak diubah)"
                            label-for="password"
                        >
                            <b-form-input
                                type="password"
                                id="password"
                                v-model="userData.password"
                                trim
                            />
                            <password v-model="userData.password" id="password-meter" strength-meter-only/>
                        </b-form-group>
                    </b-col>

                    <b-col
                        cols="12"
                        md="4"
                    >
                        <b-form-group
                            label-for="example-datepicker"
                            label="Birth date"
                        >
                            <date-picker
                                v-model="userData.tgl_lahir"
                                value-type="YYYY-MM-DD"
                                name="date"
                                placeholder="Birth date"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        md="4"
                    >
                        <b-form-group
                            label="Auth OTP"
                            label-for="authotp"
                        >
                            <b-form-input
                                readonly="readonly"
                                id="authotp"
                                v-model="userData.authotp"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        md="4"
                        class="pt-2"
                    >
                        <b-form-checkbox v-model="userData.status" v-bind:value="1" id="status">Aktif?</b-form-checkbox>
                        <b-form-checkbox v-model="userData.offline" v-bind:value="1" id="offline">Offline</b-form-checkbox>
                    </b-col>

                </b-row>

                <!-- Action Buttons -->
                <b-row class="p-1">
                    <b-button
                        variant="primary"
                        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                        type="submit"
                    >
                        Save Changes
                    </b-button>
                    <b-button
                        variant="outline-secondary"
                        type="reset"
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    >
                        Reset
                    </b-button>
                </b-row>
            </b-form>
        </b-overlay>
    </component>
</template>

<script>
import {
    BTab, BTabs, BCard, BAlert, BLink, BButton, BForm, BRow, BOverlay, BCol, BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import userStoreModule from './produkStoreModule'
import vSelect from "vue-select";

export default {
  components: {
      BFormTextarea,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
      BForm,
      BRow,
      BOverlay,
      vSelect,
      BCol,
      BFormGroup,
      BFormInput,
  },
  setup() {
    const userData = ref(null)
    const isSubmit = ref(true)
    const USER_APP_STORE_MODULE_NAME = 'app-customer'
    const UserEditFormChild = ref();
    let saved = false;
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
      const onSubmit = () => {
          isSubmit.value = true
          store.dispatch('app-customer/updateUser',{ id: router.currentRoute.params.id, UserData : userData}).then(response =>  {
              saved = true;
          }).finally(() => {
              isSubmit.value = false
              userData.value.data.password = null
              UserEditFormChild.value.foo()
          })
      }
    store.dispatch('app-customer/fetchProduk', { id: router.currentRoute.params.id })
      .then(response => {
          userData.value = response.data
          isSubmit.value = false
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })
    return {
        UserEditFormChild,
        saved,
        isSubmit,
      userData,
        onSubmit,
    }
  },
}
</script>

<style>

</style>
